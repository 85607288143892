import React, { useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'

const NavBarMobile = () => {
    const [isShwon,setIsShown] =useState(false)
    const scrollToSection = (event, sectionId) => {
        const section = document.getElementById(sectionId);
        event.preventDefault();
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='NavBarMobile'>
                <button onClick={ () => setIsShown(!isShwon)}><AiOutlineMenu /></button>
            {isShwon &&
                <nav>
                <a href='#Bemutatkozas' onClick={(e) => scrollToSection(e, 'Bemutatkozas')}>Bemutatkozás</a>
                <a href='#Szolgaltatasaink' onClick={(e) => scrollToSection(e, 'Szolgaltatasaink')}>Szolgáltatásaink</a>
                <a href='#Referenciak' onClick={(e) => scrollToSection(e, 'Referenciak')}>Referenciák</a>
                <a href='#Kapcsolat' onClick={(e) => scrollToSection(e, 'Kapcsolat')}>Kapcsolat</a>
            </nav> }
        </div>
    )
}

export default NavBarMobile