import React from 'react'

const Services = () => {
    return (
        <div className='Services' id='Szolgaltatasaink'>
            <h1>Szolgáltatásaink</h1>
            <h2>Klímaszerelés:</h2>
            <p>A GLH Klíma a klímaszerelési szolgáltatások teljes spektrumát nyújtja. Szakértőink több éves tapasztalattal rendelkeznek a klíma rendszerek telepítésében és üzembe helyezésében, és mindig készen állnak, hogy a lehető legjobb megoldást nyújtsák ügyfeleinknek.

                Kezdve a klíma kiválasztásával, mely a legjobban illeszkedik az Ön igényeihez és otthonához, majd folytatva a precíz és szakszerű telepítéssel, amely biztosítja a hosszú élettartamot és a hatékony működést. Megértjük, hogy minden otthon és üzlet egyedi, ezért minden szerelési munkát személyre szabottan végezünk, figyelembe véve az adott hely speciális igényeit és kihívásait.

                A GLH Klíma elkötelezett a legújabb technológiák és berendezések alkalmazása mellett, hogy biztosítsa a klímaberendezések optimális működését. Szerelőink rendszeresen részt vesznek képzéseken, hogy naprakészek maradjanak a legfrissebb technológiákkal és szabványokkal kapcsolatban.

                Az ügyfél elégedettsége számunkra a legfontosabb, ezért minden telepítési munka után alaposan ellenőrizzük a rendszert, hogy minden tökéletesen működik-e, és megválaszoljuk az esetleges kérdéseit. A GLH Klíma a legjobb választás a klímaszerelés terén.</p>
            <h2>Klíma karbantartás:</h2>
            <p>
                A GLH Klíma kiváló minőségű klíma karbantartási szolgáltatásokat kínál. Szakértőink pontosan tudják, hogy a rendszeres karbantartás nélkülözhetetlen a klíma berendezések hosszú élettartamához és hatékony működéséhez. A karbantartási munkáink közé tartozik a berendezés teljes vizsgálata, a szűrők tisztítása vagy cseréje, a hűtőközeg szintjének ellenőrzése, és a rendszer teljesítményének tesztelése. Emellett a karbantartás során felismerhetjük a potenciális problémákat, mielőtt azok súlyosabb hibává fejlődnének. A GLH Klíma rendszeres karbantartási szolgáltatásai növelik a klíma berendezések hatékonyságát, csökkentik az energiafogyasztást, és hosszabb élettartamot biztosítanak a berendezésnek.
            </p>
            <h2>Klíma javítás:</h2>
            <p>
                A GLH Klíma a klíma javítás minden területén rendelkezik tapasztalattal. Legyen szó bármilyen típusú vagy márkájú klíma berendezésről, szakértőink képesek gyorsan diagnosztizálni a problémát és hatékonyan megjavítani azt. Minden javítási munka során a legújabb eszközöket és technikákat használjuk, hogy a lehető legjobb eredményt érjük el. Megértjük, hogy a klíma berendezés hibája nagyon kellemetlen lehet, ezért mindig arra törekszünk, hogy a lehető legrövidebb idő alatt helyreállítsuk a rendszer működését. A GLH Klíma javítási szolgáltatásai gyorsak, hatékonyak és megbízhatóak, így ügyfeleink mindig bízhatnak bennünk.
            </p>
            <h2>Klíma tisztítás:</h2>
            <p>
                A GLH Klíma szakértői kiváló minőségű klíma tisztítási szolgáltatásokat nyújtanak. Tudjuk, hogy a tiszta klíma berendezés nemcsak hatékonyabban működik, hanem jobb levegőminőséget is biztosít. A tisztítási szolgáltatásaink közé tartozik a szűrők tisztítása vagy cseréje, a belső eg
            </p>
        </div>
    )
}

export default Services