import React from 'react'
import avatar from '../avatart.png'

const AboutUs = () => {
  return (
    <div className='AboutUs' id='Bemutatkozas'>
      <h1>Bemutatkozás</h1>
      <div className='AboutUs__Box'>
        <img src={avatar} alt='' />


        <p>Üdvözöljük a GLH Klíma weboldalán, ahol a szakértőink mindent tudnak a klímaberendezésekről. Professzionális, megbízható és gyors szolgáltatásokat nyújtunk, melyek közé tartozik a klímaszerelés, karbantartás, javítás, és tisztítás.
          A GLH Klíma vállalkozás több éves tapasztalattal rendelkezik a klímaszerelés területén. Célunk, hogy ügyfeleinknek a lehető legjobb szolgáltatást nyújtsuk, legyen szó akár új klímaszerelésről, karbantartásról, javításról vagy tisztításról. Ügyfeleink elégedettsége a legfontosabb számunkra, ezért szolgáltatásaink mindig személyre szabottak, hogy megfeleljenek az egyéni igényeknek.</p>
      </div>

    </div>
  )
}

export default AboutUs