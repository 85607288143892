import React, { useState,useEffect } from 'react'
import './galleryStyle.css'
import {SiIfixit} from 'react-icons/si'
import {AiFillLeftCircle,AiFillRightCircle} from 'react-icons/ai'

const Gallery = ({ imgs }) => {
    const [isGalleryVisible,setIsGalleryVissible] =useState(false)
    const [activeImage,setActiveImage] = useState(0)

    useEffect(() => {
        if (isGalleryVisible) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [isGalleryVisible]);
    function onClickHandler(i){
        setIsGalleryVissible(true)
        setActiveImage(i)
    }
    return (
        <div className='Gallery' id='Referenciak'>
            <h1 > Referenciák</h1>
            <div className='Gallery__image-box'>
                {imgs.map((e, i) =>
                    i > 3 ? null : <img onClick={() =>onClickHandler(i)} className='Gallery__image' src={e} alt='' key={i}/>
                )}
            </div>
            {isGalleryVisible && 
            <div className='Gallery__opened-gallery'>
                <img className='Gallery__opened-img' src={imgs[activeImage]} alt=''/>
                <div className='Gallery__exit' onClick={() => setIsGalleryVissible(false)}><SiIfixit/></div>
                <div className='Gallery__left-btn' onClick={() => activeImage===0?setActiveImage(imgs.length-1):setActiveImage(activeImage-1)}><AiFillLeftCircle/></div>
                <div className='Gallery__right-btn' onClick={() => activeImage===imgs.length-1?setActiveImage(0):setActiveImage(activeImage+1)}><AiFillRightCircle/></div>
                <div className='Gallery__img-number'>{activeImage+1}/{imgs.length}</div>
            </div>
            }
            <button className='Gallery__button' onClick={() =>onClickHandler(0)}>Összes mutatása</button>
        </div>
    )
}

export default Gallery
