import React from 'react';

const Kapcsolat = () => {
    return (
        <footer id='Kapcsolat'>
            <div className='contactUs__Box'>
                <h3>GLH Klíma</h3>
                <p className='contactUs__text'><span style={{ fontWeight: 'bold' }}>Cím:</span> 3575 Berzék, Jókai Mór utca 25</p>
                <p className='contactUs__text'><span style={{ fontWeight: 'bold' }}>Adószám:</span> 68273361-2-25</p>
            </div>
            <div className='contactUs__Box'>
                <h3>Elérhetőségek</h3>
                <p className='contactUs__text'><span style={{ fontWeight: 'bold' }}>Mobil:</span> +36 70 552 0931</p>
                <p className='contactUs__text'><span style={{ fontWeight: 'bold' }}>Email:</span> glhklima@gmail.com</p>
            </div>
            <div className='contactUs__Box'>
                <h3>Nyitvatartás</h3>
                <p className='contactUs__text'> hétfő - péntek </p>
                <p className='contactUs__text'> 08:00 - 16:00 </p>
            </div>
        </footer>
    );
};

export default Kapcsolat;