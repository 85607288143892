import React from 'react'

const NavBar = () => {
    const scrollToSection = (event,sectionId) => {
        const section = document.getElementById(sectionId);
        event.preventDefault();
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
    return (
    <div className='NavBar'>
    <nav>
        <a href='#Bemutatkozas' onClick={(e) => scrollToSection(e,'Bemutatkozas')}>Bemutatkozás</a>
        <a href='#Szolgaltatasaink' onClick={(e) => scrollToSection(e,'Szolgaltatasaink')}>Szolgáltatásaink</a>
        <a href='#Referenciak' onClick={(e) => scrollToSection(e,'Referenciak')}>Referenciák</a>
        <a href='#Kapcsolat' onClick={(e) => scrollToSection(e,'Kapcsolat')}>Kapcsolat</a>
    </nav>
    </div>
  )
}

export default NavBar