import Kapcsolat from "./components/Kapcsolat";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";

import Gallery from './gallery/Gallery'
import img1 from './gallery/photos/img1.jpeg'
import img2 from './gallery/photos/img2.jpeg'
import img3 from './gallery/photos/img3.jpeg'
import img4 from './gallery/photos/img4.jpeg'
import img5 from './gallery/photos/img5.jpeg'
import img6 from './gallery/photos/img6.jpeg'
import img7 from './gallery/photos/img7.jpeg'
import img8 from './gallery/photos/img8.jpeg'
import img9 from './gallery/photos/img9.jpeg'
import img10 from './gallery/photos/img10.jpeg'

import heroPic from'./hero1.png'
import heroPic2 from'./hero2.png'
import NavBar from "./components/NavBar";
import NavBarMobile from "./components/NavbarMobile";

const imgs =[img1,img2,img3,img4,img5,img6,img7,img8,img9,img10]

function App() {
  return (
    <div className="App">
      <NavBar />
      <NavBarMobile />
      <img className='heroImg' src={heroPic} alt='' />
      <img className='heroImgMobile' src={heroPic2} alt='' />
      <AboutUs />
      <Services />
      <Gallery imgs={imgs}/>
      <Kapcsolat />
    </div>
  );
}

export default App;
